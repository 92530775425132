
import {Component, Prop} from 'vue-property-decorator';
import {mixins} from "vue-class-component";
import ErrorMessageHandlerMixin from "../../misc/ErrorMessageHandler.mixins";
import CompanyStatistics from "@/models/CompanyStatistics.model";
import MoneyConverter from "@/misc/MoneyConverter";
import {AdvertisementSize} from "@/enum/AdvertisementSize.enum";
import {ROUTE_PROFILE} from "@/router/routes";

@Component({
  components: {
    ColumnChartComponent: () => import(
      /* webpackChunkName: "ColumnChartComponent" */
      '@/components/Misc/ColumnChart.component.vue'
    ),
    AdvertisementComponent: () => import(
      /* webpackChunkName: "AdvertisementComponent" */
      '@/components/Advertisement.component.vue'
    ),
    CompanyBalanceComponent: () => import(
      /* webpackChunkName: "CompanyBalanceComponent" */
      '@/components/Payment/CompanyBalance.component.vue'
    ),
  }
})
export default class CompanyStatisticsComponent extends mixins(ErrorMessageHandlerMixin) {
  @Prop({required: true})
  private statistic!: CompanyStatistics;

  @Prop({default: false})
  private isLoadingStatistics!: boolean;

  @Prop({required: true})
  private isLoadingWallet!: boolean;

  @Prop({required: false})
  private balance!: number | null;

  private leaderBoardAdvertisement = AdvertisementSize.STANDARD_LEADERBOARD;

  /**
   * flag that indicates if the graph is shown
   * @private
   */
  private showGraph = false;

  /**
   * returns a percentage number based on the value that is passed
   * @param value
   * @private
   */
  private formattedPercentageNumber(value?: number | null | 'NaN' | 'Infinity' | '-Infinity') {
    if(value === null || value === undefined || value === 'NaN' || value === 'Infinity' || value === '-Infinity') return '-';
    return this.$formatPercentage(value);
  }

  private get xScaling() {
    if(!this.statistic.statistics || this.statistic.statistics.length <= 0) return [];
    return this.statistic.statistics[0]?.statistics?.containersInCirculationData?.dates ?? [];
  }

  private mounted() {
    // sets a time out and activates the graph, somehow the apex charts plugin has a problem with sizing when we don't
    // use a delay
    setTimeout(() => this.showGraph = true, 100);
  }

  /**
   * returns the value or a translation if the value is null
   * @param value
   * @private
   */
  private getSafeValue(value: number) {
    return value ?? this.$t('GENERAL.UNKNOWN_SHORT');
  }

  private getSafeRoundedValue(value?: number | null | 'NaN' | 'Infinity' | '-Infinity') {
    if(!value && value !== 0 || value === 'NaN' || value === 'Infinity' || value === '-Infinity') return '-';
    return Math.round(value);
  }

  /**
   * Format to a Decimal Number
   * @param val Number to Format into a Decimal Format
   */
  private toDecimalFormat = (val: number) => MoneyConverter.toDecimalFormat(val).replace('.', ',');
}

